import api from "./index"

/**
 * Get verification videos
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getVerificationVideosRequest = (params, includes = []) => {
    return api
        .request("admin/verification-video-list")
        .withParams(params)
        .withIncludes(includes)
        .get()
}

/**
 * verification video accept || decline
 *
 * @return {*}
 * @param params
 */
export const verificationVideoUpdate = params => {
    return api
        .request("admin/verification-video")
        .withParams(params)
        .put()
}

/**
 * Find verification video by id
 *
 * @param id
 * @return {*}
 */
export const findVerificationVideoByIdRequest = params => {
    // return api.request(`admin/verification-video/${id}`).get()
    return api.request(`admin/verification-video`)
        .withParams(params)
        .get()
}