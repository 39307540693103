<template>
  <div>
    <div>
      <v-btn flat class="blue-grey ma-0" @click="returnBack()">
        <v-icon left dark class="font-size-20">
          arrow_back
        </v-icon>
        Back
      </v-btn>
    </div>
    <v-layout row>
      <v-flex xs12 sm12 md12 lg10 x10>
        <v-card class="pa-3">
          <v-progress-linear
            :active="isLoading"
            :indeterminate="isLoading"
            :height="1"
            class="pa-0 ma-0"
          />
          <div v-if="!isLoading && !!itemData">
            <v-layout row>
              <v-flex xs7>
                <div class="video-wrap">
                  <video
                    v-if="itemData.processing === 0"
                    class="item-video"
                    controls
                    playsinline
                    :src="itemData.url"
                    :poster="itemData.screen_url"
                    preload="auto"
                  >
                  </video>
                  <div v-else-if="itemData.processing === 1" class="process">
                    Video in process
                  </div>
                </div>
              </v-flex>
              <v-flex xs5>
                <div class="tit mb-3">
                  Verification video
                </div>
                <div class="break mb-3">
                  by
                  <router-link
                    :to="`/moderation/user_list/user/${itemData.user.id}`"
                    class="blue-grey--text bold link-hover"
                  >
                    <v-avatar size="40" class="mx-2">
                      <img
                        v-if="itemData.user && itemData.user.photo"
                        :src="itemData.user.photo"
                      >
                      <div
                        v-else-if="itemData.user && itemData.user.username"
                        class="avatar-letter"
                      >
                        {{ itemData.user.username.substring(0, 1) }}
                      </div>
                    </v-avatar>
                    <span>{{ itemData.user.username }}</span>
                  </router-link>
                </div>
                <div class="mb-3">
                  <div class="grey--text">
                    Choosen option:
                  </div>
                  <div class="txt">
                    {{ !itemData.model_account.watermark
                      ? `Publish content with ${siteName} watermark`
                      : 'Publish content with my own watermark'
                    }}
                  </div>
                </div>
                <div class="mb-3">
                  <div class="grey--text">
                    Website:
                  </div>
                  <div class="txt break">
                    {{ itemData.model_account && itemData.model_account.site_url
                      ? itemData.model_account.site_url
                      : 'No info about website' }}
                  </div>
                </div>
                <div v-if="itemData.decline_reason">
                  <div class="grey--text">
                    Decline reason:
                  </div>
                  <div class="txt-decline break">
                    {{ itemData.decline_reason }}
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <div class="text-xs-right mt-2">
              <template v-if="itemData.status === 'accepted' || itemData.status === 'declined'">
                <div :class="[itemData.status === 'accepted' ? 'green-text' : 'red-text', 'fw-600 status']">
                  {{ itemData.status }}
                </div>
              </template>
              <template v-else>
                <v-btn
                  color="success"
                  class="ma-0 ml-3"
                  @click="openAcceptVideoModal(itemData.id)"
                >
                  Accept
                </v-btn>
                <v-btn
                  color="error"
                  class="ma-0 ml-3"
                  @click="openRejectVideoModal(itemData.id)"
                >
                  Reject
                </v-btn>
              </template>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <accept-video-modal
      :id="videoId"
      v-model="acceptVideoModal"
      @video-accepted="videoAccepted"
    />

    <reject-video-modal
      :id="videoId"
      v-model="rejectVideoModal"
      @video-declined="videoDeclined"
    />
  </div>
</template>

<script>
import {findVerificationVideoByIdRequest} from "../../../../../api/verificationVideo"
import { mapGetters } from "vuex"
import moment from "moment"
import { log } from "../../../../../utils/helpers"
import { fetchData } from "../../../../../utils/response"
import RejectVideoModal from "../../../../../components/Moderation/VerificationVideo/RejectVideoModal"
import AcceptVideoModal from "../../../../../components/Moderation/VerificationVideo/AcceptVideoModal"
import { appName } from "../../../../../config"

export default {
  name: "VerificationVideo",
  components: {
    AcceptVideoModal,
    RejectVideoModal,
  },
  data() {
    return {
      isLoading: true,
      videoId: null,
      itemData: {},
      prevRoute: null,
      acceptVideoModal: false,
      rejectVideoModal: false,
      siteName: appName,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(["getUserInfoAndPermissions"])
  },
  created() {
    this.fetchData()
    log(moment().format())
  },
  methods: {
    returnBack() {
      if (this.prevRoute && this.prevRoute.fullPath === "/") {
        this.$router.push({ name: "all_verification_videos" })
      } else {
        this.$router.go(-1)
      }
    },
    async fetchData() {
      this.isLoading = true
      const params = {
        video_id: this.$route.params.id
      }
      await findVerificationVideoByIdRequest(params)
        .then(fetchData)
        .then(item => {
          this.itemData = item
        })
        .catch(this.$notificationHandleResponseError)
        .finally( () => {
          this.isLoading = false
        } )
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    openAcceptVideoModal(id) {
      console.log(id)
      this.videoId = id
      this.acceptVideoModal = true
    },
    openRejectVideoModal(id) {
      console.log(id)
      this.videoId = id
      this.rejectVideoModal = true
    },
    videoAccepted() {
      this.itemData.status = 'accepted'
    },
    videoDeclined() {
      this.itemData.status = 'declined'
    },
  }
}
</script>

<style scoped>
.status{
  font-size: 20px;
  text-transform: capitalize;
}
.item-video{
  max-width: 100%;
  max-height: 64vh;
  min-height: 200px;
}
.process{
  background: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-align: center;
  width: 100%;
  height: 320px;
}
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
.select-wrap {
  max-width: 75%;
}

.mt-35-p {
  margin-top: 35px;
}

.link-hover:hover .headline {
  color: #fff;
}

.link-hover:hover {
  text-decoration: none;
}
.avatar-icon {
  font-size: 64px;
}
.text-wrap {
  white-space: normal;
  word-wrap: break-word;
}
.blue-grey--text.link-hover{
  text-decoration: none;
}
.avatar-letter {
  background-color: #1b003d;
  text-transform: uppercase;
  border-radius: 50%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
}
.tit{
  font-size: 24px;

}
.txt{
  font-size: 16px;
}
.txt-decline{
  font-size: 14px;
}
</style>
